.App {
	align-items: center;
	background-color: #6ca6c1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	min-height: 100vh;
	overflow: hidden;
}

.SpinningRays {
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	max-width: 700px;
	padding: 0 50px;
	position: relative;
	width: 100%;
}

.SpinningRays-yellow-rays {
	position: absolute;
	animation: spin-clockwise 300s linear infinite;
	filter: drop-shadow(4px 3px 2px rgba(0, 0, 0, 0.4))
		drop-shadow(-4px -3px 2px rgba(0, 0, 0, 0.4));
	will-change: transform;
	width: 100%;
}

.SpinningRays-green-rays {
	position: absolute;
	animation: spin-counter-clockwise 300s linear infinite;
	filter: drop-shadow(7px 6px 4px rgba(0, 0, 0, 0.4))
		drop-shadow(-7px -6px 4px rgba(0, 0, 0, 0.4));
	will-change: transform;
	width: 80%;
}

@keyframes spin-clockwise {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin-counter-clockwise {
	100% {
		transform: rotate(-360deg);
	}
}
